<template>
  <div id="store">
    <div
      class="store-title"
      :style="{ backgroundImage: `url('${ShopForDetails.storePhoto}')` }"
    >
      <div class="store-img">
        <img :src="ShopForDetails.storeLogo" alt="" />
        <div class="store-name">
          <p>{{ ShopForDetails.storeName }}</p>
          <a href="#">联系客服</a>
        </div>
      </div>
    </div>
    <div class="store-nav">
      <div class="nav">
        <div class="nav-list">
          <router-link
            :to="`/Store/HomePage/${$route.params.id}?enterpriseId=${ShopForDetails.enterpriseId}`"
            :class="[$route.name === 'home-page' ? 'router-is-active' : '']"
            >首页</router-link
          >
          <router-link
            :to="`/Store/HomePage/${$route.params.id}/StoreCategory?enterpriseId=${ShopForDetails.enterpriseId}`"
            :class="[
              $route.name === 'store-category' ? 'router-is-active' : '',
            ]"
            >公司类目</router-link
          >
        </div>
        <div class="search">
          <input
            type="text"
            placeholder="输入关键词"
            @keyup.enter="Search"
            v-model="searchVal"
          />
          <button @click="Search">
            <svg-icon icon-class="ic-search" />搜索
          </button>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "store",
  components: {},
  data() {
    return {
      ShopForDetails: "", //店铺详情
      isHome: false,
      searchVal: "",
    };
  },
  created() {
    this.getStore();
  },
  beforeDestroy() {
    sessionStorage.removeItem("storeInfo");
  },
  methods: {
    async getStore() {
      const data = await this.$API.store.getStoreHomePage({
        object: {
          storeId: this.$route.params.id,
          location: "11",
        },
      });
      this.ShopForDetails = data.data.data;
      sessionStorage.setItem("storeInfo", JSON.stringify(this.ShopForDetails));
    },

    Search() {
      // console.log(this.$route);
      this.$router.push({
        path: `/Store/HomePage/${this.$route.params.id}/StoreCategory`,
        query: {
          enterpriseId: this.ShopForDetails.enterpriseId,
          storeLike: this.searchVal,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/store.scss";
</style>